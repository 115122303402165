var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "report-charts",
        staticStyle: {
          position: "absolute",
          left: "0",
          top: "0",
          right: "0",
          height: "99%"
        }
      },
      [
        _c("iframe", {
          attrs: {
            src:
              "http://data.twiboss.com/app/dashboards#/view/08dc03c0-1483-11eb-8d12-698d97a0dcba?_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-1d%2Cto%3Anow))",
            height: "100%",
            width: "100%"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }