//
//
//
//
//
export default {
  name: 'Kibana',
  data: function data() {
    return {};
  }
};